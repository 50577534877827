<template>
  <div>
    <modularMenu :type='type' @peopleChange='peopleChange'></modularMenu>
    <router-view ref="childmain"/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type:'0'
    }
  },
  watch:{
    $route() {
      this.type = this.$route.query.type
      this.$store.commit('set_modeActive',this.type)
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.$store.commit('set_modeActive',this.type)
  },
  methods:{
    peopleChange(id) {
      this.$refs['childmain'].peopleChange(id)
    }
  }
}
</script>